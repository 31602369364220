const translations = {
  en: {
    browse: 'Browse',
    tutorials: 'Tutorials',
    plans: 'Pricing',
    blog: 'Blog',
    aboutUs: 'About Us',
    supportCenter: 'Help',
    signUp: 'Sign up',
    signIn: 'Sign in',
    homeTitle: 'WOXO | One widget at the time',
    comingSoon: 'Coming Soon',
    comingSoonExplanation:
      'Our team is working hard to make the WOXO platform available in English too. We apologize for the inconvenience.',
    mailExistError: 'That email address is already associated with a WOXO account',
    usernameExistError: 'That username is already associated with a WOXO account',
    invalidFieldError: "Oh! This doesn't seem valid",
    invalidUsernameSpacesError: 'Oh! This contains spaces',
    createWidget: 'Create widget',
    'customer.already.plan': 'This user has already a plan',
    'error.credentials.not-found': "We couldn't find an account with the credentials you provided",
    // Standard way to write messages key
    'error.network': 'Woxo has experienced a technical issue. Please try again',
    'error.mail.exist.provider.local':
      'That email address is already associated with a WOXO account. Try {sign.up.link.login}',
    'error.mail.exist.provider.facebook':
      'That email address is already associated with a WOXO account. Try logging in with Facebook instead',
    'error.mail.exist.provider.google':
      'That email address is already associated with a WOXO account. Try logging in with Google instead',
    'error.field.email-or-username': 'Please enter a valid email address or username',
    'error.not-found.user-or-email':
      "We couldn't find an account with the email or username you entered. Please {login.up.link.sign}",
    'error.incorrect.password': 'The password you entered is incorrect',
    'login.up.link.sign': 'Sign up',
    'sign.up.link.login': 'Log in',
    'error.password.not.match': 'Oh! Passwords does not match',
    'error.invalid.email': "Oh! This doesn't seem valid email",
    'error.invalid.password.length': 'Oh! Password must have 8 or more characters',
    'message.here': 'here',
    'messages.try.again': 'try again',
    'code.verification.expired':
      'Your verification code has expired. Click {code.verification.resend}',
    'code.verification.resend': 'here to get another confirmation link',
    'code.verification.expired.no.resend': 'Your verification code has expired',
    'code.verification.not.valid':
      'Your verification code is wrong. Click in resend link to get another code',
    'email.already.confirmed': 'Your user is already verified, please reload page or login again.',
    'error.user.not.found': 'This email is not associated with any user',
    'error.user.google.found': 'This account has been created with Google. Login with Google',
    'error.user.facebook.found': 'This account has been created with Facebook. Login with Facebook',
    'message.resend.link': 'resend link',
    'pabbly.webhook.invalid': 'No pabbly webhooks valid url register',
    'pabbly.webhook.nofound': 'No pabbly webhooks find for trigger function'
  },
  es: {
    browse: 'Descubrir',
    tutorials: 'Tutoriales',
    plans: 'Planes',
    blog: 'Blog',
    aboutUs: 'Nosotros',
    supportCenter: 'Soporte',
    signUp: 'Inscribirse',
    signIn: 'Entrar',
    homeTitle: 'WOXO | Un widget a la vez ',
    comingSoon: 'Próximamente',
    comingSoonExplanation:
      'Nuestro equipo está trabajando duro para que la plataforma WOXO también esté disponible en Español. Pedimos disculpas por las molestias.',
    mailExistError: 'La dirección de correo electrónico ya está asociada con un usuario de WOXO',
    createWidget: 'Crear widget'
  },
  pt: {
    browse: 'Surfar',
    blog: 'Blog',
    tutorials: 'Tutoriais',
    plans: 'Preços',
    aboutUs: 'Sobre nós',
    supportCenter: 'Centro de Apoio',
    signUp: 'Assinar em',
    homeTitle: 'WOXO | Um widget de cada vez',
    comingSoon: 'Em breve',
    comingSoonExplanation:
      'Nossa equipe está trabalhando muito para disponibilizar a plataforma WOXO em português. Pedimos desculpas pela inconveniência.',
    createWidget: 'Criar widget'
  }
};

const translate = (key, lang, defaultValue) => {
  const language = lang || 'en';
  if (!translations[language]) {
    return defaultValue;
  }
  if (!translations[language][key]) {
    return defaultValue;
  }
  return translations[language][key];
};

export default translate;
