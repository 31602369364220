import axios from './axios';
import { api } from './app.config';
import { isArray, isObject } from '../helpers/javascript';
import translate from '../data/translations';
import { isBrowser } from '../helpers/browser';
import { getAuthHeader } from './security.service';

const get3rdPartyUserId = () => {
  const hjUserId =
    (window.getCookie &&
      typeof window.getCookie === 'function' &&
      window.getCookie('__hjUserId')) ||
    '';
  const gaUserId =
    (window.getCookie &&
      typeof window.getCookie === 'function' &&
      window.getCookie('_ga') &&
      `${window.getCookie('_ga').split('.')[2]}.${window.getCookie('_ga').split('.')[3]}`) ||
    '';
  const audience = isBrowser() ? localStorage.getItem('user-audience') || 'widget' : 'widget';
  return { hjUserId, gaUserId, audience };
};
export const create = ({ jwt, userTags }) =>
  new Promise((resolve, reject) => {
    axios({
      url: `${api}/custom/teams/user`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${jwt}`
      },
      data: { ...get3rdPartyUserId(), userTags }
    })
      .then(response => {
        if (isObject(response.data)) resolve(response.data);
        else reject(new Error(translate('providerSignUpError', 'en')));
      })
      .catch(() => {
        reject(new Error(translate('invalidLoginError', 'en')));
      });
  });

export const invite = ({ emails }) =>
  new Promise((resolve, reject) => {
    axios({
      url: `${api}/custom/teams/invite`,
      method: 'POST',
      headers: getAuthHeader(),
      data: { emails }
    })
      .then(response => {
        if (response.data.success && isArray(response.data.success)) resolve(response.data.success);
        else reject(new Error(translate('error.network', 'en')));
      })
      .catch(() => {
        reject(new Error(translate('error.network', 'en')));
      });
  });

export const acceptInvitation = ({ code }) =>
  new Promise((resolve, reject) => {
    axios({
      url: `${api}/custom/teams/invite/accept`,
      method: 'POST',
      headers: getAuthHeader(),
      data: { code }
    })
      .then(response => {
        if (response.data.success) resolve(response.data.success);
        else reject(new Error(translate(response.data.error, 'en')));
      })
      .catch(() => {
        reject(new Error(translate('error.network', 'en')));
      });
  });

export const members = ({ team }) =>
  new Promise((resolve, reject) => {
    axios({
      url: `${api}/custom/teams/members`,
      headers: { ...getAuthHeader(), 'X-Mission-Control-Team': team }
    })
      .then(response => {
        if (response.data.success) resolve(response.data.success);
        else reject(new Error(translate(response.data.error, 'en')));
      })
      .catch(() => {
        reject(new Error(translate('error.network', 'en')));
      });
  });

export const remove = user =>
  new Promise((resolve, reject) => {
    axios({
      url: `${api}/custom/teams/members/${user}`,
      method: 'DELETE',
      headers: getAuthHeader()
    })
      .then(response => {
        if (response.data.success) resolve(response.data.success);
        else reject(new Error(translate('error.network', 'en')));
      })
      .catch(() => {
        reject(new Error(translate('error.network', 'en')));
      });
  });

export const teams = () =>
  new Promise((resolve, reject) => {
    axios({
      url: `${api}/custom/teams/user`,
      method: 'GET',
      headers: getAuthHeader()
    })
      .then(response => {
        if (response.data.success) resolve(response.data.success);
        else reject(new Error(response.data.error));
      })
      .catch(() => {
        reject(new Error(translate('error.network', 'en')));
      });
  });

export const getDefaultTeam = () =>
  new Promise((resolve, reject) => {
    axios({
      url: `${api}/custom/teams/default`,
      method: 'GET',
      headers: getAuthHeader()
    })
      .then(response => {
        if (response.data.success) resolve(response.data.success);
        else reject(new Error(response.data.error));
      })
      .catch(() => {
        reject(new Error(translate('error.network', 'en')));
      });
  });

export const saveBranding = branding =>
  new Promise((resolve, reject) => {
    axios({
      url: `${api}/custom/teams/branding`,
      method: 'POST',
      headers: getAuthHeader(),
      data: { branding }
    })
      .then(response => {
        if (response.data.success) resolve(response.data.success);
        else reject();
      })
      .catch(e => {
        reject();
      });
  });

export const changeCurrentTeam = team =>
  new Promise((resolve, reject) => {
    axios({
      url: `${api}/custom/profiles/change-current-team`,
      method: 'POST',
      headers: getAuthHeader(),
      data: { team }
    })
      .then(response => {
        if (response.data.success) resolve(response.data.success);
        else reject();
      })
      .catch(e => {
        reject();
      });
  });
