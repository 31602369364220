import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
// eslint-disable-next-line import/no-cycle
import { getAuthHeader } from './security.service';
import { isArray, isObject } from '../helpers/javascript';
import { isBrowser, registerRequest } from '../helpers/browser';

const stripePromise = loadStripe(process.env.STRIPE_PK);

const redirectToCheckout = async sessionId => {
  const stripe = await stripePromise;
  // eslint-disable-next-line no-return-await
  return await stripe.redirectToCheckout({ sessionId });
};

export const plans = config =>
  new Promise(resolve => {
    const headers = (config && config.headers) || getAuthHeader();
    if (isBrowser()) {
      axios({
        url: `${process.env.API_HOST}/custom/payments/plan`,
        method: 'POST',
        headers
      })
        .then(response => {
          if (isObject(response.data) && response.data.success) {
            resolve({ ...response.data.success, userOwner: response.data.userOwner });
          } else resolve(false);
        })
        .catch(e => {
          resolve(false);
        });
    } else resolve(null);
  });

export const session = ({ price, type = 'stripe' }) => {
  axios({
    url: `${process.env.API_HOST}/custom/payments/session`,
    method: 'POST',
    headers: getAuthHeader(),
    data: { url: window.location.href, price, type }
  }).then(response => {
    if (isObject(response.data) && response.data.session) {
      if (response.data.type === 'checkout') redirectToCheckout(response.data.session.id);
      else window.location.href = response.data.session.url;
    }
  });
};

export const prices = ({ category }) =>
  new Promise(resolve => {
    let result = null;
    try {
      if (sessionStorage.getItem(`plans-${category}`)) {
        result = JSON.parse(sessionStorage.getItem(`plans-${category}`));
      }
    } catch (error) { }
    if (isArray(result)) resolve(result);
    else {
      const cancelToken = registerRequest('products');
      axios({
        url: `${process.env.API_HOST}/custom/payments/products?category=${category}`,
        method: 'GET',
        cancelToken
      })
        .then(response => {
          if (isArray(response.data)) {
            let toResolve = response.data.sort((a, b) => {
              if (a.price > b.price) return 1;
              if (b.price > a.price) return -1;
              return 0;
            });
            // toResolve.map(p => {
            //   if (p.type !== "Free" && p.interval && p.interval === 'year') {
            //     p.specialOffer = {
            //       active: true,
            //       reason: 'Black Friday',
            //       price: (Math.round(((p.price / 2) + Number.EPSILON) * 100) / 100).toFixed(2) - 0.01
            //     }
            //   }
            // });
            sessionStorage.setItem(`plans-${category}`, JSON.stringify(toResolve));
            resolve(toResolve);
          } else resolve([]);
        })
        .catch(() => {
          resolve([]);
        });
    }
  });

export const appsumoPrices = () =>
  new Promise(resolve => {
    let result = null;
    try {
      if (sessionStorage.getItem(`plans-appsumo`)) {
        result = JSON.parse(sessionStorage.getItem(`plans-appsumo`));
      }
    } catch (error) { }
    if (isArray(result)) resolve(result);
    else {
      const cancelToken = registerRequest('appsumo-products');
      axios({
        url: `${process.env.API_HOST}/custom/payments/appsumoProducts`,
        method: 'GET',
        cancelToken
      })
        .then(response => {
          if (isArray(response.data)) {
            const toResolve = response.data.sort((a, b) => {
              if (a.price > b.price) return 1;
              if (b.price > a.price) return -1;
              return 0;
            });
            sessionStorage.setItem(`plans-appsumo`, JSON.stringify(toResolve));
            resolve(toResolve);
          } else resolve([]);
        })
        .catch(() => {
          resolve([]);
        });
    }
  });

export const manageCart = ({ status }) =>
  new Promise(() => {
    axios({
      method: 'POST',
      url: `${process.env.API_HOST}/custom/payments/manageCart?status=${status}`,
      headers: getAuthHeader()
    });
  });
