export default [{
  name: 'Happy dreams',
  url: 'https://res.cloudinary.com/dakp804eh/video/upload/v1615481766/woxo-video/audios/2017-04-14_-_Happy_Dreams_-_David_Fesliyan.mp3'
}, {
  name: "It's a good day",
  url: 'https://res.cloudinary.com/dakp804eh/video/upload/v1615481767/woxo-video/audios/2020-10-19_-_Its_A_Good_Day_-_www.FesliyanStudios.com_Steve_Oxen.mp3'
}, {
  name: 'Easy Going',
  url: 'https://res.cloudinary.com/dakp804eh/video/upload/v1615481770/woxo-video/audios/2020-09-30_-_Easy_Going_-_David_Fesliyan.mp3'
}, {
  name: 'Silly chicken',
  url: 'https://res.cloudinary.com/dakp804eh/video/upload/v1615481757/woxo-video/audios/2018-10-06_-_Silly_Chicken_-_David_Fesliyan.mp3'
}, {
  name: 'I was joking',
  url: 'https://res.cloudinary.com/dakp804eh/video/upload/v1615481752/woxo-video/audios/LOOP_B_2019-05-04_-_I_Was_Joking_-_David_Fesliyan.mp3'
}, {
  name: 'Happy Go Lucky',
  url: 'https://res.cloudinary.com/dakp804eh/video/upload/v1616112176/woxo-video/audios/06_-_Happy-Go-Lucky_-_www.FesliyanStudios.com_Steve_Oxen.mp3'
}, {
  name: "Can't Touch Me",
  url: 'https://res.cloudinary.com/dakp804eh/video/upload/v1616112182/woxo-video/audios/07_-_Can_t_Touch_Me_-_www.FesliyanStudios.com_David_Renda.mp3'
}, {
  name: 'Crazy Crowd',
  url: 'https://res.cloudinary.com/dakp804eh/video/upload/v1616112174/woxo-video/audios/08_-_Crazy_Crowd_-_www.FesliyanStudios.com_David_Renda.mp3'
}, {
  name: 'Shakalaka',
  url: 'https://res.cloudinary.com/dakp804eh/video/upload/v1616112174/woxo-video/audios/09_-_Shakalaka_-_FesliyanStudios.com_-_David_Renda.mp3'
}, {
  name: 'Snap Along',
  url: 'https://res.cloudinary.com/dakp804eh/video/upload/v1616112191/woxo-video/audios/10_-_Snap_Along_-_David_Fesliyan.mp3'
}, {
  name: 'Escape Chase',
  url: 'https://res.cloudinary.com/dakp804eh/video/upload/v1616112177/woxo-video/audios/11_-_Escape_Chase_-_David_Fesliyan.mp3'
}, {
  name: 'Final Boss',
  url: 'https://res.cloudinary.com/dakp804eh/video/upload/v1616112177/woxo-video/audios/12_-_Final_Boss_-_David_Fesliyan.mp3'
}, {
  name: 'Inspirational Advertising',
  url: 'https://res.cloudinary.com/dakp804eh/video/upload/v1616112176/woxo-video/audios/13_-_Inspirational_Advertising_1_-_David_Fesliyan.mp3'
}, {
  name: 'Tears of Joy',
  url: 'https://res.cloudinary.com/dakp804eh/video/upload/v1616112185/woxo-video/audios/14_-_Tears_of_Joy_-_David_Fesliyan.mp3'
}, {
  name: 'Flying High',
  url: 'https://res.cloudinary.com/dakp804eh/video/upload/v1616112181/woxo-video/audios/15_-_Flying_High_-_www.FesliyanStudios.com_Steve_Oxen.mp3'
}, {
  name: 'Go Beyond',
  url: 'https://res.cloudinary.com/dakp804eh/video/upload/v1616112181/woxo-video/audios/16_-_Go_Beyond_-_David_Fesliyan.mp3'
}, {
  name: 'A Better Life',
  url: 'https://res.cloudinary.com/dakp804eh/video/upload/v1616112187/woxo-video/audios/17_-_A_Better_Life_-_FesliyanStudios.com_-_David_Renda.mp3'
}, {
  name: 'Emotion',
  url: 'https://res.cloudinary.com/dakp804eh/video/upload/v1616112189/woxo-video/audios/18_-_Emotion_-_David_Fesliyan.mp3'
}, {
  name: 'They Said I Cant',
  url: 'https://res.cloudinary.com/dakp804eh/video/upload/v1616112193/woxo-video/audios/19_-_They_Said_I_Cant_-_David_Fesliyan.mp3'
}, {
  name: 'The Lounge',
  url: 'https://res.cloudinary.com/dakp804eh/video/upload/v1616112186/woxo-video/audios/20_-_The_Lounge_-_www.fesliyanstudios.com_-_David_Renda.mp3'
}, {
  name: 'Super Spiffy',
  url: 'https://res.cloudinary.com/dakp804eh/video/upload/v1616112189/woxo-video/audios/21_-_Super_Spiffy_-_David_Fesliyan.mp3'
}, {
  name: 'Trusted Advertising',
  url: 'https://res.cloudinary.com/dakp804eh/video/upload/v1616112187/woxo-video/audios/22_-_Trusted_Advertising_-_www.fesliyanstudios.com.mp3'
}, {
  name: 'Touch Of Love',
  url: 'https://res.cloudinary.com/dakp804eh/video/upload/v1616112190/woxo-video/audios/23_-_Touch_Of_Love_-_FesliyanStudios.com_-_David_Renda.mp3'
}, {
  name: 'Simplicity',
  url: 'https://res.cloudinary.com/dakp804eh/video/upload/v1616112194/woxo-video/audios/24_-_Simplicity_-_David_Fesliyan.mp3'
}, {
  name: 'Mastermind',
  url: 'https://res.cloudinary.com/dakp804eh/video/upload/v1616100923/woxo-video/audios/25_-_Mastermind_-_www.FesliyanStudios.com_Steve_Oxen.mp3'
}];