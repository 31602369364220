export default [{
  name: 'directional-left',
  value: 'directional-left'
}, {
  name: 'directional-right',
  value: 'directional-right'
}, {
  name: 'directional-up',
  value: 'directional-up'
}, {
  name: 'directional-down',
  value: 'directional-down'
}, {
  name: 'random',
  value: 'random'
}, {
  name: 'directional-left',
  value: 'directional-left'
}, {
  name: 'dummy',
  value: 'dummy'
}, {
  name: 'Directional',
  value: 'Directional'
}, {
  name: 'displacement',
  value: 'displacement'
}, {
  name: 'windowslice',
  value: 'windowslice'
}, {
  name: 'BowTieVertical',
  value: 'BowTieVertical'
}, {
  name: 'BowTieHorizontal',
  value: 'BowTieHorizontal'
}, {
  name: 'SimpleZoom',
  value: 'SimpleZoom'
}, {
  name: 'LinearBlur',
  value: 'LinearBlur'
}, {
  name: 'WaterDrop',
  value: 'WaterDrop'
}, {
  name: 'InvertedPageCurl',
  value: 'InvertedPageCurl'
}, {
  name: 'GlitchMemories',
  value: 'GlitchMemories'
}, {
  name: 'PolkaDotsCurtain',
  value: 'PolkaDotsCurtain'
}, {
  name: 'StereoViewer',
  value: 'StereoViewer'
}, {
  name: 'luminance_melt',
  value: 'luminance_melt'
}, {
  name: 'perlin',
  value: 'perlin'
}, {
  name: 'directionalwarp',
  value: 'directionalwarp'
}, {
  name: 'Bounce',
  value: 'Bounce'
}, {
  name: 'wipeRight',
  value: 'wipeRight'
}, {
  name: 'wipeLeft',
  value: 'wipeLeft'
}, {
  name: 'wipeDown',
  value: 'wipeDown'
}, {
  name: 'wipeUp',
  value: 'wipeUp'
}, {
  name: 'morph',
  value: 'morph'
}, {
  name: 'ColourDistance',
  value: 'ColourDistance'
}, {
  name: 'CircleCrop',
  value: 'CircleCrop'
}, {
  name: 'Swirl',
  value: 'Swirl'
}, {
  name: 'CrossZoom',
  value: 'CrossZoom'
}, {
  name: 'Dreamy',
  value: 'Dreamy'
}, {
  name: 'GridFlip',
  value: 'GridFlip'
}, {
  name: 'ZoomInCircles',
  value: 'ZoomInCircles'
}, {
  name: 'Radial',
  value: 'Radial'
}, {
  name: 'Mosaic',
  value: 'Mosaic'
}, {
  name: 'undulatingBurnOut',
  value: 'undulatingBurnOut'
}, {
  name: 'crosshatch',
  value: 'crosshatch'
}, {
  name: 'cannabisleaf',
  value: 'cannabisleaf'
}, {
  name: 'CrazyParametricFun',
  value: 'CrazyParametricFun'
}, {
  name: 'ButterflyWaveScrawler',
  value: 'ButterflyWaveScrawler'
}, {
  name: 'kaleidoscope',
  value: 'kaleidoscope'
}, {
  name: 'windowblinds',
  value: 'windowblinds'
}, {
  name: 'hexagonalize',
  value: 'hexagonalize'
}, {
  name: 'GlitchDisplace',
  value: 'GlitchDisplace'
}, {
  name: 'DreamyZoom',
  value: 'DreamyZoom'
}, {
  name: 'DoomScreenTransition',
  value: 'DoomScreenTransition'
}, {
  name: 'ripple',
  value: 'ripple'
}, {
  name: 'pinwheel',
  value: 'pinwheel'
}, {
  name: 'angular',
  value: 'angular'
}, {
  name: 'burn',
  value: 'burn'
}, {
  name: 'circle',
  value: 'circle'
}, {
  name: 'circleopen',
  value: 'circleopen'
}, {
  name: 'colorphase',
  value: 'colorphase'
}, {
  name: 'crosswarp',
  value: 'crosswarp'
}, {
  name: 'cube',
  value: 'cube'
}, {
  name: 'directionalwipe',
  value: 'directionalwipe'
}, {
  name: 'doorway',
  value: 'doorway'
}, {
  name: 'fade',
  value: 'fade'
}, {
  name: 'fadecolor',
  value: 'fadecolor'
}, {
  name: 'fadegrayscale',
  value: 'fadegrayscale'
}, {
  name: 'flyeye',
  value: 'flyeye'
}, {
  name: 'heart',
  value: 'heart'
}, {
  name: 'luma',
  value: 'luma'
}, {
  name: 'multiply_blend',
  value: 'multiply_blend'
}, {
  name: 'pixelize',
  value: 'pixelize'
}, {
  name: 'polar_function',
  value: 'polar_function'
}, {
  name: 'randomsquares',
  value: 'randomsquares'
}, {
  name: 'rotate_scale_fade',
  value: 'rotate_scale_fade'
}, {
  name: 'squareswire',
  value: 'squareswire'
}, {
  name: 'squeeze',
  value: 'squeeze'
}, {
  name: 'swap',
  value: 'swap'
}, {
  name: 'wind',
  value: 'wind'
}];