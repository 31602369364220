import translate from '../data/translations';
export var mailExistProviderError = function mailExistProviderError(_ref) {
  var provider = _ref.provider;

  switch (provider) {
    case 'facebook':
      return translate('error.mail.exist.provider.facebook', 'en');

    case 'google':
      return translate('error.mail.exist.provider.google', 'en');

    default:
      return translate('error.mail.exist.provider.local', 'en');
  }
};
export var premiumValidation = function premiumValidation(_ref2) {
  var isPremium = _ref2.isPremium,
      plan = _ref2.plan;

  if (isPremium) {
    if (plan) {
      if (plan.currentProWidgets < plan.proWidgets || plan.proWidgets === -1) {
        return true; // eslint-disable-next-line no-else-return
      } else {
        // eslint-disable-next-line no-throw-literal
        throw {
          message: "Looks like you already used all your premium widgets. You\u2019ll need to upgrade your plan to install it or"
        };
      }
    } else {
      // eslint-disable-next-line no-throw-literal
      throw {
        message: "Hey! This is a premium template, You\u2019ll need to upgrade your plan to install it or"
      };
    }
  } // eslint-disable-next-line no-else-return
  else return true;
};
export var isPaidWidgetPlan = function isPaidWidgetPlan(plan) {
  return !!(plan && (plan.category === 'widget' || plan.type === 'appsumo'));
};