import TagManager from 'react-gtm-module';
export var gtmFireEvent = function gtmFireEvent(_ref) {
  var _ref$event = _ref.event,
      event = _ref$event === void 0 ? 'onButtonClick' : _ref$event,
      _ref$category = _ref.category,
      category = _ref$category === void 0 ? 'general' : _ref$category,
      action = _ref.action,
      label = _ref.label,
      gclid = _ref.gclid;
  var id = '';

  try {
    id = gclid || window.getCookie('gclid') || '';
  } catch (error) {}

  var tagManagerArgs = {
    gtmId: 'GTM-NMP7265',
    dataLayer: {
      event: event,
      category: category,
      action: action,
      label: label,
      gclid: id
    }
  };

  try {
    TagManager.initialize(tagManagerArgs);
  } catch (error) {}
};